
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.57573 0.575737C0.810044 0.341423 1.18994 0.341423 1.42426 0.575737L3.99999 3.15147L6.57573 0.575737C6.81004 0.341423 7.18994 0.341423 7.42426 0.575737C7.65857 0.810052 7.65857 1.18995 7.42426 1.42427L4.84852 4L7.42426 6.57574C7.65857 6.81005 7.65857 7.18995 7.42426 7.42427C7.18994 7.65858 6.81004 7.65858 6.57573 7.42427L3.99999 4.84853L1.42426 7.42427C1.18994 7.65858 0.810044 7.65858 0.57573 7.42427C0.341415 7.18995 0.341415 6.81005 0.57573 6.57574L3.15147 4L0.57573 1.42427C0.341415 1.18995 0.341415 0.810052 0.57573 0.575737Z" fill="#1D2433"/>
</svg>

  </template>

  <script>
  export default {
    name: 'XMark',
    inheritAttrs: true,
  }
  </script>
